<template>
  <div class="matchmakerIntro">
    <div class="box">
      <div class="box-c">
        <v-h5Mtml :content="postData.content"></v-h5Mtml>
      </div>
    </div>
  </div>
</template>

<script>
import { getDsfContentInfoListUrl } from "./api";
export default {
  name: "matchmaker",
  data() {
    return {
      postData: {
        content: "",
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  async mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let params = {
        curPage: 1,
        pageSize: 99,
        status: 1,
      };
      let res = await this.$axios.get(`${getDsfContentInfoListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        let oList = res.data && res.data.records;
        if (oList && oList.length != 0) {
          oList.forEach((element) => {
            if (element.type == "红娘简介") {
              this.postData = element;
            }
          });
        }
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.matchmakerIntro {
  min-height: 100vh;
  background: #fff6f6;
  position: relative;
  .box {
    background: url("./img/back.png") no-repeat;
    background-size: 100% 100%;
    width: 90%;
    height: 90%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 110px 28px 58px 30px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 32px;
    color: #211f1f;
    line-height: 56px;
    .box-c {
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>
